<template>
  <b-card>
    <div>
      <div id="card">
        <div id="form">
          <!-- Formulário 1 -->
          <validation-observer ref="refFormObserver1">
            <b-form class="p-2" @submit.prevent="submitForm1('tokenFacebook')" @reset.prevent="resetForm1">
        <!-- Token facebook -->
              <validation-provider v-slot="{ errors }" name="tokenFacebook" rules="required">
                <b-form-group label="Token de acesso Facebook" label-for="tokenFacebook">
                  <div class="d-flex">
                    <b-form-input
                      id="tokenFacebook"
                      :state="errors.length > 0 ? false : null"
                      :class="{ 'border-danger': errors.length > 0 || isTokenEmpty(tokenFacebook, sub1) }"
                      placeholder="Insira o token"
                      v-model="tokenFacebook"
                    />
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="ml-2" type="submit" :disabled="isBusy1">
                      <b-spinner v-if="isBusy1" />
                      <template v-else>
                        Salvar
                      </template>
                    </b-button>
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form>
    </validation-observer>

          <!-- Formulário 2 -->
          <validation-observer ref="refFormObserver2">
            <b-form class="p-2" @submit.prevent="submitForm1('tokenGoogle')" @reset.prevent="resetForm2">
              <!-- Token google -->
              <validation-provider v-slot="{ errors }" name="submitForm1" rules="required">
                <b-form-group label="Token de acesso Google" label-for="toketokenGooglen2">
                  <div class="d-flex">
                    <b-form-input
                      id="tokenGoogle"
                      :state="errors.length > 0 ? false : null"
                      :class="{ 'border-danger': errors.length > 0 || isTokenEmpty(tokenGoogle, sub2) }"
                      placeholder="Insira o token"
                      v-model="tokenGoogle"
                    />
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="ml-2" type="submit" :disabled="isBusy2">
                      <b-spinner v-if="isBusy2" />
                      <template v-else>
                        Salvar
                      </template>
                    </b-button>
                  </div>
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form>
          </validation-observer>

          <!-- Form Actions (se desejar algum botão que afete ambos os formulários) -->
          <div class="d-flex mt-2">
            <!-- Coloque aqui os botões de ação que se aplicam aos dois formulários -->
          </div>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import axios from '@axios'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      tokenGoogle: '', // Variável para armazenar o token do formulário 1
      sub1: 0,
      tokenFacebook: '',
      sub2: 0, // Variável para armazenar o token do formulário 2
      isBusy1: false, // Variável para controlar o estado do botão de salvar do formulário 1
      isBusy2: false, // Variável para controlar o estado do botão de salvar do formulário 2
    }
  },
  methods: {
    submitForm1(token) {
      this.sub1 = 1
      const payload = new FormData()
      console.log('Antes')
      console.log(token)
      console.log('Depois')

      if(token == 'tokenGoogle'){
        payload.append('tokenGoogle', this.tokenGoogle)

      }else{
        payload.append('tokenFacebook', this.tokenFacebook)

      }
      
      axios.post(`${process.env.VUE_APP_API}/social_token`, payload)
        .then(response => {
          if(response.data.response.tokenFacebook != null){
            this.tokenFacebook = response.data.response.tokenFacebook.value;
          }
          if(response.data.response.tokenGoogle != null){
            this.tokenGoogle = response.data.response.tokenGoogle.value;
          }
          
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Sucesso ao atualizar!',
              icon: 'InfoIcon',
              variant: 'success',
            }
          })
          this.$emit('close')
          this.$emit('onSaved', true)
        })
        .catch(error => {
          console.log(error)
        })
      console.log(payload)
    },
    getTokenSocial(){
      axios.get(`${process.env.VUE_APP_API}/social_token`)
        .then(response => {
          if(response.data.response.tokenFacebook != null){
            this.tokenFacebook = response.data.response.tokenFacebook.value;
          }
          if(response.data.response.tokenGoogle != null){
            this.tokenGoogle = response.data.response.tokenGoogle.value;
          }
        })
        .catch(error => {
          console.log(error);
        });
    },
    resetForm1() {
      this.tokenGoogle = '';
    },
    resetForm2() {
      this.token2 = '';
    },
    isTokenEmpty(token, sub) {
      console.log(token, sub)
      return token.trim() === '' && sub === 1;
    }
  },
  beforeMount() {
    this.getTokenSocial()
  },
}
</script>

<style scoped>
.border-danger {
  border-color: red !important;
}
#card {
  width: 100%;
  min-height: 364px;
  border-radius: 6px;
  padding: 36px 42px;
}

#form-title {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 26px;
}

#tokenGoogle,
#tokenFacebook {
  width: 100%;
}
</style>
